<template>
  <div class="star-img" :class="[isBigShadow == 2?'star-img-b':'']" :id="idName"></div>
</template>

<script>
import { toRefs, getCurrentInstance, onBeforeMount, reactive, onMounted, onBeforeUnmount } from 'vue';
import * as THREE from 'three';
import TrackballControls from 'three-trackballcontrols';
import imgMt4 from "../public/images/mt4.png"
export default {
  props: ['sphereImg', 'starSize', 'starWidth', 'starHeight', 'isBigShadow', 'idNameBF'],
  setup(props,{ emit }) {
    const { proxy } = getCurrentInstance();
    let webGLRenderer = null
    let camera = null
    let scene = null
    let sphere = null
    let orbitControls = null
    var requestAni = null;
    const windowWidth = props.starWidth || 390
    const windowHeight = props.starHeight || 390
    const state = reactive({
      idName: 'star-img'
    })
    onBeforeMount(() => {
        let timeStr = new Date().getTime()
        state.idName = 'star-img-' + timeStr + Math.random()
        if (props.idNameBF) state.idName = props.idNameBF
    })
    onMounted(() => {

        init()
    })
    const initRenderer = function () {
        webGLRenderer = new THREE.WebGLRenderer({ alpha: true });
        webGLRenderer.setSize(windowWidth, windowHeight);
        webGLRenderer.shadowMapEnabled = false
        // webGLRenderer.setClearColor(new THREE.Color('transparent'));  // color, alpha
        document.getElementById(state.idName).appendChild(webGLRenderer.domElement);
    }
    const initCamera = function () {
        camera = new THREE.PerspectiveCamera(45, windowWidth / windowHeight, 1, 1000);  // 创建透视相机
        camera.position.z = 400;
        camera.up.y = 1;
        camera.lookAt(new THREE.Vector3(0, 0, 0));
    }
    const initScene = function () {
        scene = new THREE.Scene();
    }
    const initLight = function () {
        const ambiLight = new THREE.AmbientLight(0x333333);
        scene.add(ambiLight);

        const direLight = new THREE.DirectionalLight(0xffffff, 1.0);  // 平行光 DirectionalLight (光源颜色的RGB数值, 光源强度数值) 
        direLight.position.set(500, 500, 500);
        scene.add(direLight);
    }
    const initObject = function () {
        const loader = new THREE.TextureLoader();
        let icon = props.sphereImg || "https://mystimages.oss-cn-hongkong.aliyuncs.com/mystipfs/test/star/5_1.png"
        let mat = new THREE.MeshBasicMaterial({ map: loader.load(icon) });
        sphere = new THREE.Mesh(new THREE.SphereGeometry(props.starSize || 100, 60, 60), mat); // 第一个参数设置球体半径
        sphere.material.needsUpdate = true;         //告诉Three.js需要更新材质

        // // 加载贴图
        // let texture = loader.load(imgMt4);
        // // 发光颜色
        // let light = 'rgba(255,255,255,1)';
        // // 点精灵材质
        // let spriteMaterial = new THREE.SpriteMaterial({
        //     map: texture,//贴图
        //     color: light,
        //     // rotation:Math.PI/4,
        //     blending: THREE.AdditiveBlending,//在使用此材质显示对象时要使用何种混合。加法
        // });
        // let sprite = new THREE.Sprite(spriteMaterial);
        // // 发光范围
        // sprite.scale.set(300, 300, 1);
        // sphere.add(sprite);

        scene.add(sphere);
    }
    const initOrbit = function () {
        orbitControls = new TrackballControls(camera, webGLRenderer.domElement);
        orbitControls.noZoom = true;                //禁止放大缩小
    }
    const render = function () {
        // 使用requestAnimationFrame按照浏览器定义时间间隔调用函数，并保证动画尽量平滑、高效
        requestAni = requestAnimationFrame(render);
        // 球体自动绕Y轴旋转
        if (sphere) sphere.rotation.y -= 0.002;
        // 在对摄像机的转换进行任何手动更改之后，必须调用orbitControls.update()
        orbitControls.update();
        webGLRenderer.render(scene, camera, true);
    }
    //更换纹理
    const replaceTexture = function(t){
        const TextureLoader = new THREE.TextureLoader()  
        let newTexture = TextureLoader.load(t);
        sphere.material.map = newTexture;           // 新的纹理
        sphere.material.needsUpdate = true;         //告诉Three.js需要更新材质
    }
    // 初始化函数
    const init = function () {
        // 创建 WebGLobal 渲染器
        initRenderer();
        // 生成相机
        initCamera();
        // 生成场景
        initScene();
        // 添加光源
        initLight();
        // 添加物体
        initObject();
        // 添加轨道控件
        initOrbit();
        // 渲染
        // webGLRenderer.render(scene, camera);
        render();
        
    }
    // 清除模型
    const dispose = function (parent, child) {
      if (child.children.length) {
        let arr = child.children.filter(x => x);
        arr.forEach(a => {
          dispose(child, a)
        })
      }
      if (child instanceof THREE.Mesh || child instanceof THREE.Line) {
        if (child.material.map) child.material.map.dispose();
        child.material.dispose();
        child.geometry.dispose();
        child.geometry = null;
      } else if (child.material) {
        child.material.dispose();
      }
      child.remove();
      parent.remove(child);
    }
    onBeforeUnmount(() => {
      webGLRenderer.forceContextLoss()
      webGLRenderer = null
      if (requestAni != null) {
        cancelAnimationFrame(requestAni);
        let arr = scene.children.filter(x => x)
        arr.forEach(a => {
          dispose(scene, a);
        })
        // setTimeout(() => { getStarList() });  
      }
    })
    return {
        initRenderer,
        initCamera,
        initScene,
        initLight,
        initObject,
        initOrbit,
        render,
        init,
        replaceTexture,
        ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.star-img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 110%;
    height: 0;
    padding-bottom: 110%;
    display: block;
    content: '';
    background: url('../public/images/mt4.png') no-repeat;
    background-size: 100% 100%;
    z-index: -1;
    opacity: 1;
  }
  &.star-img-b {
    &::before {
      width: 130%;
      padding-bottom: 130%;
    }
  }
}
</style>